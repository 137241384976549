
import {
  defineComponent,
  ref,
  useContext,
  useFetch,
  computed,
} from '@nuxtjs/composition-api';
import { usePagePreloader, usePage, useBreadcrumbs } from '~/composables';

export default defineComponent({
  setup() {
    const { page, fetchPage } = usePage();
    const { route } = useContext();
    const pagePreloader = usePagePreloader();
    const { breadcrumbs, addCrumbs } = useBreadcrumbs();

    const pageSidebar = ref(null);
    const pageBlocks = ref([]);

    const splitContentToSidebarAndBlocks = () => {
      const clonePageBlocks = [...page.value.blocks];
      const sidebarIndex = clonePageBlocks.findIndex(
        (b) => b.type === 'json' && b.content.type === 'app-left-sidebar',
      );

      pageSidebar.value = clonePageBlocks
        .splice(sidebarIndex, 1)
        .pop().content.data;

      pageBlocks.value = [...clonePageBlocks];
    };

    useFetch(async () => {
      pagePreloader.start();

      await fetchPage(route.value.params.slug);

      const isContainSidebar = page?.value?.blocks.find(
        (b) => b.type === 'json' && b.content.type === 'app-left-sidebar',
      );

      if (isContainSidebar) {
        splitContentToSidebarAndBlocks();
      }

      addCrumbs(page?.value?.title);
      pagePreloader.stop();
    });

    const isOnlyTextareaBlocks = computed(() =>
      page?.value?.blocks?.every((block) => block.type === 'textarea'),
    );

    return {
      page,
      pageBlocks,
      pageSidebar,
      breadcrumbs,
      isOnlyTextareaBlocks,
    };
  },
  head: {},
});
