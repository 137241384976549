
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { useBreakpoint } from '~/composables';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('lg');
    const { route } = useContext();
    const router = useRouter();

    const sidebarMenu = computed(() =>
      props.data?.menu?.filter(({ active }) => active !== false),
    );

    const pageSlug = ref(route.value.params.slug);

    const navigateToPage = (slug) => {
      router.push(slug);
    };

    return { isDesktop, pageSlug, sidebarMenu, navigateToPage };
  },
});
